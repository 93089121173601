import { withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export const Text = styled.span<StyledSystemProps>`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  ${withStyledSystem}
`;
