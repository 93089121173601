import { ReactComponent as AmEx } from '@m/assets/svg/payment-amex.svg';
import { ReactComponent as Card } from '@m/assets/svg/payment-creditcard.svg';
import { ReactComponent as DinersClub } from '@m/assets/svg/payment-dinersclub.svg';
import { ReactComponent as Discover } from '@m/assets/svg/payment-discover.svg';
import { ReactComponent as Jcb } from '@m/assets/svg/payment-jcb.svg';
import { ReactComponent as MasterCard } from '@m/assets/svg/payment-mastercard.svg';
import { ReactComponent as Visa } from '@m/assets/svg/payment-visa.svg';
import { Box } from '@m/components/uikit';
import { type Visit } from '@m/types/api';

import { Text } from '../Text';

import type { StyledSystemProps } from '@m/theme';

interface PaymentMethodProps extends StyledSystemProps {
  paymentInfo?: Visit['oneTimePaymentMethodInfo'];
}

function getVisitCard(paymentInfo: Visit['oneTimePaymentMethodInfo'] | undefined) {
  if (paymentInfo) {
    const { brand } = paymentInfo;
    switch (brand.toUpperCase()) {
      case 'VISA':
        return <Visa width={45} height={32} />;
      case 'MASTERCARD':
        return <MasterCard width={45} height={32} />;
      case 'AMERICAN EXPRESS':
      case 'AMEX':
        return <AmEx width={45} height={32} />;
      case 'DINERS CLUB':
        return <DinersClub width={45} height={32} />;
      case 'DISCOVER':
        return <Discover width={45} height={32} />;
      case 'JCB':
        return <Jcb width={45} height={32} />;
      default:
        return <Card width={45} height={32} />;
    }
  }
  return <Card width={45} height={32} />;
}

export function PaymentMethod({ paymentInfo, ...boxProps }: PaymentMethodProps) {
  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      marginTop="40px"
      marginBottom="2px"
      {...boxProps}
    >
      {getVisitCard(paymentInfo)}
      <Text fontSize="18px" lineHeight={1} color="#000" marginLeft="8px">
        ending in &bull;&bull;&bull;&nbsp;
        {paymentInfo?.last4Digits}
      </Text>
    </Box>
  );
}
