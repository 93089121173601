import { withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export const Heading = styled.div<StyledSystemProps>`
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: left;
  margin: 8px 0;
  ${withStyledSystem}
`;
