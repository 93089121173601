import { withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export const Box = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 10px;
  margin-top: 10px;
  width: 100%;

  @media (min-width: 360px) {
    padding: 20px;
  }
  ${withStyledSystem}
`;

export const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: 100%;
  height: 88px;
  flex-shrink: 0;

  @media (max-width: 340px) {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    margin: 0 -10px;
    width: calc(100% + 20px);
  }
`;

export const ButtonBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 48px;
`;

export const Heading = styled.div`
  position: absolute;
  z-index: 2;
  top: -12px;
  left: 50%;
  margin-left: -80px;
  width: 160px;
  height: 18px;
  background-color: #fff;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

export const LoadingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #e6e6e6;
`;
