import { ReactComponent as Lock } from '@m/assets/svg/lock.svg';
import { ReactComponent as PaymentCard } from '@m/assets/svg/payment-creditcard.svg';
import { Button } from '@m/components/uikit';
import { fontTTHoves } from '@m/styled/mixins';
import { withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export const Placeholder = styled.div`
  ${fontTTHoves}
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.5);
`;

export const CardIcon = styled(PaymentCard)`
  position: absolute;
  top: 18px;
  left: 16px;
  width: 23px;
  height: 17px;
`;

export const PayButton = styled(Button)<StyledSystemProps>`
  ${fontTTHoves}
  width: 100%;
  height: 72px;
  box-shadow: none;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #fff;
  padding: 0 44px;
  position: relative;

  &:disabled {
    opacity: 0.35;
    filter: none;
  }
  ${withStyledSystem}
`;

export const LockIcon = styled(Lock)<StyledSystemProps>`
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -14px;
  fill: currentColor;
  ${withStyledSystem}
`;

export const PolicyLink = styled.a<StyledSystemProps>`
  display: inline-block;
  color: #000;
  font-weight: 600;
  padding: 2px 3px;
  margin: -2px 0;
  text-decoration: none;
  border-radius: 2px;

  &:active {
    text-decoration: underline;
    background-color: rgba(0, 0, 0, 0.075);
  }
  ${withStyledSystem}
`;
PolicyLink.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};
