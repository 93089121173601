import { useMemo, type HTMLAttributes } from 'react';

import {
  StyledButton,
  primaryButtonCss,
  secondaryButtonCss,
  tertiaryButtonCss,
} from './Button.styled';

import type { StyledSystemProps } from '@m/theme';

type StyledSystemButton = HTMLAttributes<HTMLButtonElement> & StyledSystemProps;

export interface ButtonProps extends StyledSystemButton {
  variant?: 'primary' | 'secondary' | 'tertiary';
}

export function Button({ variant, ...buttonProps }: ButtonProps) {
  const buttonCss = useMemo(() => {
    switch (variant) {
      case 'primary':
        return primaryButtonCss;
      case 'secondary':
        return secondaryButtonCss;
      case 'tertiary':
        return tertiaryButtonCss;
      default:
        return '';
    }
  }, [variant]);

  return <StyledButton css={buttonCss} {...buttonProps} />;
}
