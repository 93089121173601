import { useState } from 'react';

import { ReactComponent as AmEx } from '@m/assets/svg/payment-amex.svg';
import { ReactComponent as DinersClub } from '@m/assets/svg/payment-dinersclub.svg';
import { ReactComponent as Discover } from '@m/assets/svg/payment-discover.svg';
import { ReactComponent as MasterCard } from '@m/assets/svg/payment-mastercard.svg';
import { ReactComponent as Visa } from '@m/assets/svg/payment-visa.svg';
import LoadingSpinner from '@m/components/LoadingSpinner';
import { Box } from '@m/components/uikit';
import { fontStackDMSans } from '@m/styled/values';
import theme from '@m/theme';
import { type Visit } from '@m/types/api';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { type StripeCardElement } from '@stripe/stripe-js';

// import Checkbox from '@m/components/Checkbox';

// These are imported directly from the source file to
// avoid circular dependencies.
import { DividerLabel } from '../DividerLabel/DividerLabel';
import { Heading } from '../Heading/Heading.styled';
import { PageSection } from '../Page/Page.styled';
import { Text } from '../Text/Text.styled';

import * as Styled from './PayWithCard.styled';

interface PayWithCardProps {
  visit: Visit | null;
  saveForLater: boolean;
  onChangeSaveForLater: (saveForLater: boolean) => void;
  supportsPaymentRequest: boolean | null;
  onCardTokenReceived: (token: string) => void;
}

export const stripeCardOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#000',
      fontFamily: fontStackDMSans,
    },
    invalid: {
      color: theme.colors.coral,
    },
  },
};

export function PayWithCard({
  visit,
  saveForLater,
  onChangeSaveForLater,
  supportsPaymentRequest,
  onCardTokenReceived,
}: PayWithCardProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [canSubmit, setCanSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    const res = await stripe.createToken(elements.getElement(CardElement) as StripeCardElement);

    if (res.token) {
      onCardTokenReceived(res.token.id);
    }
  };

  return (
    <PageSection>
      {supportsPaymentRequest && <DividerLabel marginBottom="24px">Or pay with card</DividerLabel>}
      <Heading>Payment method</Heading>
      <Text>All transactions are secure and encrypted.</Text>
      <Box
        marginTop="24px"
        marginBottom="36px"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        css={`
          & svg {
            margin-right: 12px;
          }
        `}
      >
        <Visa width={45} height={32} />
        <MasterCard width={45} height={32} />
        <AmEx width={45} height={32} />
        <Discover width={45} height={32} />
        <DinersClub width={45} height={32} />
      </Box>
      <Box
        width="100%"
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-start"
        height="auto"
        minHeight="60px"
        overflow="hidden"
        position="relative"
      >
        <CardElement
          options={stripeCardOptions}
          onChange={(event) => (event.complete ? setCanSubmit(true) : setCanSubmit(false))}
          css={`
            width: 100%;
            height: 60px;
            border: 2px solid #c4c4c4;
            border-radius: 4px;
            padding: 18px;
          `}
        />
      </Box>
      {/*
        // Disabling until supported by api

        <Box margin="24px 0">
          <Checkbox
            label="Save my information for a faster exit"
            checked={saveForLater}
            onChange={(e) => onChangeSaveForLater(e.target.checked)}
            labelPosition="right"
            width="18px"
            height="18px"
            css={`
              appearance: checkbox;
              accent-color: currentColor;
            `}
            labelProps={{
              fontFamily: fontStackDMSans,
              letterSpacing: 'auto',
              fontSize: '17px',
              marginTop: '3px',
              fontWeight: '400',
              lineHeight: 1.1,
              color: '#000',
            }}
          />
        </Box>
      */}
      <Styled.PayButton
        variant="primary"
        disabled={!canSubmit || processing}
        marginY="24px"
        onClick={handleSubmit}
      >
        {visit?.itemizedAmounts?.totalPriceAmount && !processing ? (
          <>
            {`Pay $${visit.itemizedAmounts.totalPriceAmount.toFixed(2)}`}
            <Styled.LockIcon />
          </>
        ) : (
          <LoadingSpinner color="#fff" />
        )}
      </Styled.PayButton>
      <Text
        color="rgba(0,0,0,0.6)"
        fontSize="14px"
        fontWeight={400}
        lineHeight={1.71428571}
        letterSpacing="0.01em"
      >
        <Text>By tapping Pay, you agree to the&#x20;</Text>
        <Styled.PolicyLink href="https://metropolis.io/terms/">Terms of Service</Styled.PolicyLink>

        <Text>&#x20;and</Text>
        <Styled.PolicyLink href="https://metropolis.io/privacy/">
          <Text>Data and Privacy Policy</Text>
        </Styled.PolicyLink>
      </Text>
    </PageSection>
  );
}
