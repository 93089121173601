import { withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export const Separator = styled.div<StyledSystemProps>`
  width: 100%;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  ${withStyledSystem}
`;
