import { ReactComponent as CloseRounded } from '@m/assets/svg/close-rounded.svg';
import { fadeIn, slideUp } from '@m/styled/keyframes';
import { normalizeButton, maxFullHeightMobile } from '@m/styled/mixins';
import { withStyledSystem } from '@m/theme';
import styled, { css } from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export const View = styled.div<StyledSystemProps & { show?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 5;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  user-select: none;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.54);
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition: opacity 250ms linear;
  transition-delay: ${({ show }) => (show ? '0ms' : '250ms')};
  animation: ${({ show }) => (show ? fadeIn : 'none')} 250ms linear 0ms 1 backwards;
  ${withStyledSystem}
`;

export const ClickAway = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: inherit;
  touch-action: inherit;
`;

export const Content = styled.div<StyledSystemProps & { show?: boolean }>`
  ${maxFullHeightMobile}
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: auto;
  flex-grow: 0;
  flex-shrink: 1;
  background-color: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  pointer-events: inherit;
  touch-action: inherit;
  transform: translate3d(0, ${({ show }) => (show ? '0%' : '100%')}, 0);
  transition: transform 400ms ease;
  transition-delay: ${({ show }) => (show ? '200ms' : '0ms')};
  animation: ${({ show }) => (show ? slideUp : 'none')} 400ms ease 200ms 1 backwards;
  ${withStyledSystem}
  ${withStyledSystem}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 56px;
  flex-shrink: 1;
`;
export const CloseIcon = styled(CloseRounded)`
  width: 24px;
  height: 24px;
  fill: currentColor;
`;
export const CloseButton = styled.button`
  ${normalizeButton}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-right: 6px;
  color: #000;
  border-radius: 22px;

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.7);
  }
`;
CloseButton.defaultProps = {
  type: 'button',
};

export const Scroller = styled.div<{ withHeader?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: calc(100% - ${({ withHeader }) => (withHeader ? 56 : 0)}px);
  position: relative;
  touch-action: none;

  & * {
    touch-action: inherit;
  }

  @media (max-height: 670px) {
    overflow: auto;
    touch-action: pan-y;
  }
`;

export const ModalPrompt = styled.div<StyledSystemProps>`
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
  padding: 8px;
  flex-shrink: 1;
  ${withStyledSystem}
`;

export const ModalSection = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 8px 24px;
  ${withStyledSystem}
`;

export const modalButtonCss = css`
  ${normalizeButton}
  width: 100%;
  height: 54px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  border-radius: 4px;

  &:active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }
`;
