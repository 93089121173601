import type { ReactNode } from 'react';

import { PageBox } from './Page.styled';

import type { StyledSystemProps } from '@m/theme';

interface PageProps extends StyledSystemProps {
  children: ReactNode;
}

export function Page(boxProps: PageProps) {
  return <PageBox {...boxProps} />;
}
